<template>
  <div class="readme-article">
    <h1 id="学员关怀">三、学员关怀</h1>
    <h2 id="近期需回访">1.近期需回访</h2>
    <p>查看近期有预约需要回访的正式学员.</p>
    <p><img src="@/assets/img/education/jw3-1.png" /></p>
    <h2 id="近期已回访">2.近期已回访。</h2>
    <p>查看近期有预约已回访的正式学员</p>
    <p><img src="@/assets/img/education/jw3-2.png" /></p>
    <h2 id="回访记录">3.回访记录</h2>
    <p>查看正式学员的回访记录列表，包含回访方式、回访内容等信息。</p>
    <p><img src="@/assets/img/education/jw3-3.png" /></p>
  </div>
</template>

<script>
export default {
  name: "Education3-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>